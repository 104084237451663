/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FFFFFF !important;
  color: #000 !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar img {
  scale: 1.5;
}

nav.navbar.scrolled {
  padding: 1rem 0;
  background-color: #FFFFFF;
}

nav.navbar.scrolled img {
  scale: 2;
}

nav.navbar a.navbar-brand {
  width: 10%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 700;
  color: #9c0000 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  color: #000;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: #000000;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 2px solid #ff0000;
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  scale: 1 !important;
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);

}

.navbar-text button {
  font-weight: 700;
  color: #000;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.2s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.2s ease-in-out;
}

.navbar-text button:hover {
  color: #ffffff;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: url(./assets/img/bars-solid.svg);
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 0px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: #000;
}

/************ Banner Css ************/
.banner {
  margin-top: 5vh;
  padding: 8rem 0 8rem 0;
  /*
  background-image: url('./assets/img/banner-bg.png');
  */
  background-color: #ffffff;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

/*
  .banner .tagline {
  font-weight: 500;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #fe2b2baf -5.91%, #eb0000e9 111.58%);
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin-bottom: 16px;
  display: inline-block;
}
*/
.banner h1 {
  margin-top: 12vh;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  margin-top: 10vh;
  color: #000;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/

.skillCol {
  background-color: #ffffff00;
  align-items: center;
  align-content: center;
  row-gap: 2rem;
}
.card {
  background-color: #000;
  align-items: center;
}

.container .card .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: #2c73df;*/
  background-color: #ffffff00;
}

.container .card .icon .fa {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: #ffffff00;
}

.container .card .slide {
  width: 300px;
  height: 200px;
  transition: 0.5s;
  
}

.container .card .slide.slide1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: .7s;
  transform: translateY(100px);
  
}

.container .card:hover .slide.slide1 {
  transform: translateY(0px);
}

.container .card .slide.slide2{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  transition: .8s;
  transform: translateY(-100px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  background-color: #ffffff00 !important;
  color:#ffffff00 !important;

}

.container .card:hover .slide.slide2{
  transform: translateY(100px);
  padding-bottom: 5rem;
  margin-bottom: 5rem;
  background-color: #fff !important;
  color:#ffffff !important;
}

.container .card:hover .slide.slide2 .content p{
  color:#000;
}

.container .card:hover .slide.slide2 .content h3{
  color:#000;
}


.container .card .slide.slide2::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 4px;
  bottom: 15px;
  left: 50%;
  left: 50%;
  transform: translateX(-50%);
  /*background: #2c73df;*/
  background-color: #fff;
}


.container .card .slide.slide2 .content p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #ffffff00;
}

.container .card .slide.slide2 .content h3 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 24px;
  text-align: center;
  color: #ffffff00;
}

.skillsMain {
  /*background-image: url('./assets/img/fondo8.jpeg');*/
  background-color: #000;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  height: fit-content;
  padding: 2rem;
}

.skill-bx {
  background-image: url('./assets/img/fondo7.jpeg');
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #070bd597;
  border-radius: 64px;
  text-align: center;
  margin: 1rem 5rem;
  padding: 5rem;
  box-shadow: #151515;
}

.introSkills h3 {
  background-color: #000000e1;
  border-radius: 63px;
  padding: 2rem;
  color: #fff;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  
    .navbar-expand-md{
      background-color: #fff !important;
      
    }


    nav.navbar .navbar-toggler:active,
    nav.navbar .navbar-toggler:focus {
     background-color: #fff !important;
     
    }

    .banner{
      margin-top: 0;
      padding-top: 0%;    
    }
    .banner h1 {
      text-align: center;
    } 
    .banner p{
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0;
      padding-top: 0%; 
      padding-bottom: 0rem;  
      font-size: 16px;
      font-weight: 500;    
    }
    .banner img{
      margin-top: -3rem;
      padding-top: 0%;
    }

    .skill-bx{
      background-image: url('./assets/img/fondo9.jpeg');
      background-color: #00000010;
      margin: 0%;
      padding: 0%;
      align-items: center;
      align-content: center;

     }

     .introSkills {
        
        padding-top: 1rem;
        padding-bottom: 1rem;
        align-content: center;
      }
      .skillsMain{
        margin: 0%;
        padding: 0%;
        align-items: center;
        align-content: center;
        color: #fff;
      }

      .introSkills h3 {
        background-color: #00000095;
        border-radius: 63px;
        padding: 2rem;
        color: #fff;
      }
   
  }

.skill h2 {
  font-size: 60px;
  font-weight: 700;
}

.skill p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
  text-align: left;
  gap: 1rem;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item {
  width: 100%;
  padding: 15px;
}

.background-image-left {
  top: 30%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 28px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}


@media only screen and (max-width: 700px) {

    .nav-link#projects-tabs-tab-first {
      font-size: 12px;
    }
    .nav-link#projects-tabs-tab-second {
      font-size: 12px;
    }
    .nav-link#projects-tabs-tab-third {
      font-size: 12px;
    }

    .nav-link#projects-tabs-tab-fourth {
      font-size: 12px;
    }
    .whatsAppIcon{
      display: none;
    }

    .project h4{
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 0;
      padding-top: 0%; 
      padding-bottom: 1rem;  
      font-size: 18px;
      font-weight: 500;    
    }

    .project p{
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 0;
      padding-top: 0%; 
      padding-bottom: 2rem;  
      font-size: 18px;
      font-weight: 500;    
    }
}


.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #3d5add -5.91%, #291873 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: #fff;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #000;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.8px;
  color: #000;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #ea7171 -5.91%, #e72a2a 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (max-width: 700px) {
  .contact h2 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .contact p {
    font-size: 20px;
  }
} 

.contact p {
  font-size: 26px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.buttonContacto {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.buttonContacto span {
  z-index: 1;
  position: relative;
}

.buttonContacto:hover {
  color: #fff;
}

.buttonContacto::before {
  content: "WhatsApp";
  align-content: space-around;
  color: #fff;
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.buttonContacto:hover::before {
  width: 100%;
  color: #fff;
}


/************ Footer Css ************/
.footer {
  /*background-image: url('./assets/img/footer-bg.png');
*/
background-color: #000;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}



.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}


/************ Whatsapp Css ************/

.whatsAppIcon a img {
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 60%;
  right: 30px;
  z-index: 99;
}

.contact p a img {
  height: 150px;
  width: 150px;

}